<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row
        class="spacer mb-6"
        no-gutters
      >
        <v-col cols="3">
          <p class="text-left mb-4">
            <span class="font-weight-bold mr-1">Host: </span> {{ workersList[0].device_name }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Ip address: </span> {{ workersList[0].ip_address }}
          </p>
        </v-col>
        <v-col cols="3">
          <p
            :class="[workersList[0].version === vesion ? 'green--text text--darken-1' : 'red--text']"
            class="text-left mb-4"
          >
            <span class="font-weight-bold mr-1">Version: </span> {{ workersList[0].version }} {{ workersList[0].version === vesion ? 'ok' : 'Not ok' }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Free </span> {{ freeCount }} of {{ workersList.length }}
          </p>
        </v-col>
        <v-col cols="3">
          <p
            v-if="hasInactive"
            class="text-left mb-4"
          >
            <span class="font-weight-bold red--text mr-1">Has inactive!</span>
          </p>
          <p
            v-if="hasChallenge"
            class="text-left mb-0"
          >
            <span class="font-weight-bold red--text mr-1">Has challenge!</span>
          </p>
        </v-col>
        <v-col cols="3">
          <a
            :href="href"
            class="href"
            target="_blank"
          > Open panel </a>
        </v-col>
      </v-row>
      <div />
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <worker-item
        v-for="worker in workersList"
        :key="worker._id"
        :worker="worker"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
  import WorkerItem from '@/components/workersPage/WorkerItem';
  import { getPanelHref } from '@/mixins/getPanelHref';
  import { mapGetters } from 'vuex';

  export default {
    name: 'WorkersList',
    components: {
      WorkerItem,
    },
    mixins: [getPanelHref],
    props: {
      workersList: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      ...mapGetters({
        vesion: 'workers/version',
      }),
      hasInactive () {
        return this.workersList.some(worker => worker.status !== 'active');
      },
      hasChallenge () {
        return this.workersList.some(worker => worker.credentials && worker.credentials.challenged_at);
      },
      freeCount () {
        return this.workersList.length - this.workersList.filter(worker => worker.credentials).length;
      },
      href () {
        return this.getPanelHref(this.workersList[0].device_name);
      },
    },
  };
</script>

<style scoped>

</style>
